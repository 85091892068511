import React from 'react'
import Navbar from './../inc/Navbar';

import ScrollToTop from "react-scroll-to-top";

import { Link } from 'react-router-dom';
function Menpage() {
  return (
    <div>
      <Navbar/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <ScrollToTop smooth top="100" color="Darkblue"/>
      <div className="album py-2">
        
        <div className="container">

            <div className="row">
       
            <div className="col-md-4 mt-6" >
                        <div className="card mb-4 border-White box shadow">
                  
                            <div className="card-body">
                                <center>
                                <h2><b style={{color:"Gray"}}> JEANS</b></h2>
                                </center>
                                <center>
                                <Link to="/jeans">
                               <button className="btn btn-dark">Click Now</button>
                               </Link>
                               </center>
                    
                                <div className="d-flex justify-content-between align-items-center">
                                        
                            </div>
                            </div>
                            </div>
                               
                            </div>
                            <div className="col-md-4 mt-6" >
                        <div className="card mb-4 border-white box shadow">
                       
                            <div className="card-body">
                            <center>
                                <h2><b style={{color:"Gray"}}> T-SHIRT</b></h2>
                                </center>
                            <center>
                            <Link to="/tshirt">
                               <button className="btn btn-dark">Click Now</button>
                               </Link>
                               </center>


<div className="d-flex justify-content-between align-items-center">
                                
                                        
                            </div>
                            </div>
                            </div>
                               
                            </div>
                            
          
                            
            <div className="col-md-4 mt-6" >
                        <div className="card mb-4 border-White box shadow">
                     
                            <div className="card-body">
                            <center>
                                <h2><b style={{color:"Gray"}}> CASUAL-SHIRT</b></h2>
                                </center>
                                <center>
                                <Link to="/casual">
                               <button className="btn btn-dark">Click Now</button>
                               </Link>
                               </center>
                    
                                <div className="d-flex justify-content-between align-items-center">
                                        
                            </div>
                            </div>
                            </div>
                               
                            </div>
                            <div className="col-md-4 mt-6" >
                        <div className="card mb-4 border-white box shadow">
                    
                            <div className="card-body">
                            <center>
                                <h2><b style={{color:"Gray"}}> MEN BLAZER</b></h2>
                                </center>
                            <center>
                            <Link to="/blazer">
                               <button className="btn btn-dark">Click Now</button>
                               </Link>
                               </center>


<div className="d-flex justify-content-between align-items-center">
                                
                                        
                            </div>
                            </div>
                            </div>
                               
                            </div>
                            </div>
                            </div>
                            </div>
    </div>
  )
}

export default Menpage
