import React from 'react';
import {Link} from 'react-router-dom';
import MRINDIANCODER from "../images/mr.indiancoder.jpg";
import  "./Footer.css";
function Footer() {
    return(
      
     <div className="shoppingbazaar">
         <br></br>
         <br/>
        
    <div className="album py-3">
        
        <div className="container">

            <div className="row">
            <div className="col-md-4">
              
                 <h6>SHOPPING BAZAAR</h6>
                   <hr/>
                     <ul className="list-unstyled">
                     <h6 className="card-text">Showroom Address</h6>
                                <p className="card-text mb-2">Natidanga More, Karimpur<br/> NADIA,  WEST BENGNA - 741152</p>
                                <p className="card-text mb-2"> <br/>Copyright © Shopping Bazaar</p>
           
            
         
          
          </ul>
          </div>
         
                 <div className="col-md-2">
                     <h6>About Us</h6>
                     <hr/>
                     <ul class="list-unstyled">
                       <Link to="/about" target="_blank" rel="noopener noreferrer">
                     <li className="mb-2" ><small>About</small></li></Link>
                     


                     </ul>
                 </div>
                 <div className="col-md-2">
                 <h6>PRODUCT</h6>
                   <hr/>
                     <ul className="list-unstyled">
                     <Link to="/women" target="_blank" rel="noopener noreferrer">
                        <li className="mb-2" > <small>Women</small></li>
                                            </Link>
                                          
                                            <Link to="/men" target="_blank" rel="noopener noreferrer">
                        <li className="mb-2" > <small>Men</small></li>
                                            </Link>
                                            <Link to="/kids" target="_blank" rel="noopener noreferrer">
                        <li className="mb-2" > <small>Kids</small></li>
                                            </Link>
                                            
              
            
          </ul>
          </div>
          <div className="col-md-2">
                 <h6>CONTACT</h6>
                   <hr/>
                     <ul className="list-unstyled">
                     <Link to="/Contact" target="_blank" rel="noopener noreferrer">
               <li className="mb-2"><small>Contact Us</small></li></Link>
            
               

          </ul>
          
          </div>
          
          <div className="col-md-2">
                 <h6>SOCIAL LINKS</h6>
                   <hr/>
                     <ul className="list-unstyled">

            <li className="mb-2"> <a href="https://www.facebook.com/profile.php?id=61550064333094" target="_blank" rel="noopener noreferrer">
                 <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-facebook" viewBox="0 0 16 16">
  <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"/>
</svg> <small> Facebook</small></a> </li>
           

        

<li className="mb-2"> <a href="https://www.youtube.com/channel/UCpZu1l9vwlqF_I9vO_6gsJw" target="_blank" rel="noopener noreferrer"> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-youtube" viewBox="0 0 16 16">
  <path d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.007 2.007 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.007 2.007 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31.4 31.4 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.007 2.007 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A99.788 99.788 0 0 1 7.858 2h.193zM6.4 5.209v4.818l4.157-2.408L6.4 5.209z"/>
</svg> <small> Youtube </small> </a> </li>

           <br/>
        
          </ul>
          
          </div>

        
          
                 </div>
                 </div>
                 </div>
               
               
                 <br></br><br/>

    <div className="album py-3">
        
        <div className="container">

            <div className="row">
            <div className="col-md-4">
     
                 <h4>SHOPPING BAZAAR</h4>
                 </div>
                 <div className="col-md-4">
                 <p> Copyright © 2024, Shopping Bazaar </p>
                
                 </div>
                 <div className="col-md-4">
                 <small> Development & Design by : <a href="https://www.mrindiancoder.com/" target="_blank"  rel="noopener noreferrer"> www.mrindiancoder.com</a> </small>
                <small>  <a style={{color:"white"}}  href="https://www.mrindiancoder.com/" target="_blank"  rel="noopener noreferrer">  MR. INDIAN CODER </a> </small>
                <a href="https://www.mrindiancoder.com/" target="_blank" rel="noopener noreferrer"> <img src={MRINDIANCODER} width="85" height="30" alt="mr.indiancoder"/> </a>
                 </div>
                 
                 </div>
                 </div>
                 </div>
                 <br/>
                
                  
             </div>
               
         
     
    );
}
export default Footer;